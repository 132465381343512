import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { createUseStyles } from "react-jss";

// import ReactPlayer from "react-player";

// --------Importing videos for WEB----------------
// const talkingVideo = require('../assets/videos/talkingweb.mp4')
// const silenceVideo = require('../assets/videos/blink_web.mp4')
// const video_poster = require('../assets/videos/post.png')


// --------Importing videos for Mobile----------------

import talkingVideoMob from '../../assets/videos/talkingMan.webm'
import silenceVideoMob from '../../assets/videos/blinkingMan.webm'
import video_posterMob from '../../assets/videos/snapMan.png'


const VideoView = (props, ref) => {
    const style = useStyle()
    const videoPlayer = useRef()
    const [isVideoStart, setIsVideoStart] = useState(false)
    let { chatIsClear } = props

    useEffect(() => {

    }, [chatIsClear])

    useImperativeHandle(ref, () => ({
        playVideo: () => {
            // document.querySelector('video').playbackRate = 1;
            setIsVideoStart(true)
            videoPlayer.current.play()
        },
        stopVideo: () => {
            setIsVideoStart(false)
            videoPlayer.current.play()
        },
    }));

    return (
        <>
            {/* {window.innerWidth <= 600 ?
                <video ref={videoPlayer} loop muted id="videoBox"
                    className={` ${chatIsClear ? "videoblur" : ""}`}
                    src={isVideoStart ? talkingVideoMob : silenceVideoMob}
                    poster={video_posterMob}
                ></video>
                :
                <video ref={videoPlayer} loop muted id="videoBox"
                    className={` ${chatIsClear ? "videoblur" : ""}`}
                    src={isVideoStart ? talkingVideo : silenceVideo}
                    poster={video_poster}
                ></video>
            } */}

            <video ref={videoPlayer} loop muted id="videoBox"
                className={` ${chatIsClear ? "videoblur" : ""}`}
                src={isVideoStart ? talkingVideoMob : silenceVideoMob}
                poster={video_posterMob}
            ></video>
        </>
    );
}

const useStyle = createUseStyles({
    // video:{
    //     height:'100%'
    // }
})

export default forwardRef(VideoView);
