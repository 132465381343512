import React, { useImperativeHandle, useEffect, forwardRef, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import succeeded_animation from '../../assets/animation/succeeded_animation.json'
import Lottie from 'react-lottie';
import { Modal } from 'react-bootstrap';

const SessionLogoutModal = (props, ref) => {

    let { onPressNewSession,onPressBackSession, onPressLogout } = props

    const [isEndedExpired, setIsEndedExpired] = useState(false)

    const style = useStyle({ isEndedExpired })


    useImperativeHandle(ref, () => ({
        show: () => {
            setIsEndedExpired(true)
        },
        hide: () => {
            setIsEndedExpired(false)
        },
    }));

    const defaultOptions = {
        loop: false,
        autoplay: false,
        animationData: succeeded_animation,
        rendererSettings: {
            //   preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <Modal show={isEndedExpired} animation={true} size="md" centered>
            
            <Modal.Body className={style.ModalBody} id="chatExpiryPopupView">
                <Lottie options={defaultOptions}
                    height={'8vh'}
                    width={'8vh'} 
                    isPaused={false}
                    />
                <h3 className={style.sessionModalHeader}>Session Paused</h3>
                {/* <h6 className={style.sessionModalDesc}>Much obliged for halting by. We desire to see you again soon!</h6> */}
                <h6 className={style.sessionModalDesc}>Much obliged for halting by. Select any option to continue.</h6>
                <div className="d-flex justify-content-around">
                <div>
                    <button className={style.sessionModalButton} onClick={() => onPressNewSession()}>Restart Session</button>
                </div>
                <div>
                    <button className={style.sessionModalButton} onClick={() => onPressBackSession()}>Continue Session</button>
                </div>
                <div>
                    <button className={style.sessionLogoutButton} onClick={() => onPressLogout()}>Logout</button>
                </div>
                </div>
             
            </Modal.Body>
        </Modal>
        // <div className={style.chatExpiryScreen}>
        //     <div className={style.chatExpiryPopupView} id="chatExpiryPopupView" >
        //         <Lottie options={defaultOptions}
        //             height={'8vh'}
        //             width={'8vh'}/>
        //         <h3 className={style.sessionModalHeader}>Session Ended</h3>
        //         <h6 className={style.sessionModalDesc}>Much obliged for halting by. We desire to see you again soon!</h6>
        //         <button className={style.sessionModalButton} onClick={() => onPressNewSession()}>New Session</button>
        //     </div>
        // </div>
    );
}

const useStyle = createUseStyles({

    chatExpiryScreen: {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 0,
        zIndex: 12000,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "top 0.5s ease-in-out",
        top: (props) => props.isEndedExpired ? 0 : '-100%',

    },
    chatExpiryPopupView: {
        width: '30%',
        height: '40%',
        backgroundColor: 'white',
        alignSelf: 'center',
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    sessionModalHeader: {
        textAlign: 'center',
        color: '#429EFA'
    },
    sessionModalDesc: {
        textAlign: 'center',
        color: '#429EFA'
    },
    sessionModalButton: {

        width: "130px",
        color: 'white',
        alignSelf: 'center',
        backgroundColor: '#429EFA',
        borderColor: 'transparent',
        padding: '8px',
        fontSize: "13px",
        borderRadius: 100,
        marginTop: '20px',
        minWidth: "130px",

    },
    sessionLogoutButton: {
 
        width: "130px",
        minWidth: "130px",
        color: 'white',
        alignSelf: 'center',
        backgroundColor: '#dc3545',
        borderColor: 'transparent',
        padding: '8px',
        borderRadius: 100,
        marginTop: '20px',
        fontSize: "13px",

    }
})

export default forwardRef(SessionLogoutModal);
