// import { useSpeechSynthesis } from "react-speech-kit";
import ic_cross from "../../assets/icons/ic_cross.png";
import ic_chat from "../../assets/icons/ic_chat.png";
import ic_logout from "../../assets/icons/ic_logout.png";
import ic_mic from "../../assets/icons/ic_mic.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { v4 as uuidv4 } from "uuid";
import { Audio, Bars } from "react-loader-spinner";
import moment from "moment";
import useSpeechToText from "react-hook-speech-to-text";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import useAuth from "../../Authentication/useAuth";
import "../../assets/styles/style.css";
import axiosInstance from "../../axios.js";
import * as faceapi from "face-api.js";

import {
  VideoView,
  ChatList,
  SessionExpireModal,
  CompanyLogo,
  PolicyAgreementModal,
  SessionEndedModal,
  SessionLogoutModal,
  SessionCompletedModal,
} from "../chatModule";

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";

// const axios = require("axios");

var startTime = null;
var CHAT_SESSOIN = "";
var MIC_IS_RECOGNIZING = false;
var CHATBOT_IS_SPEAKING = false;
var CHAT_START_TIME = "";

var NAME = "";
var AGE = "";
var GENDER = "";
var MARITAL = "";
var DIAGNOSIS = "";

var CHAT_LIST = [];
var Session = [];
var face_expressionArr = [];

const Main = () => {
  const [isChatOrClear, setIsChatOrClear] = useState(true);
  const [havePermission, setPermission] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isMicEnable, setIsMicEnable] = useState(true);
  const style = useStyle({ isChatOrClear, isSessionExpired, isMicEnable });
  const videoViewRef = useRef();
  const chatListRef = useRef();
  const sessionExpireModalRef = useRef();
  const sessionEndedModalRef = useRef();
  const SessionLogoutModalRef= useRef();
  const SessionCompletedModalRef= useRef();
  const policyAgreementModalRef = useRef();
  const { logout, user } = useAuth();
  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  const { nextSession, setNextSession } = useState("");

  const detectionObj = {
    angry: 0,
    disgusted: 0,
    fearful: 0,
    happy: 0,
    neutral: 0,
    sad: 0,
    surprised: 0,
  };

  // Handling Session Number

  // ------------Facial Expressions------------

  const videoHeight = 480;
  const videoWidth = 640;
  const videoElement = useRef();
  const FacialExpressions = useRef(null);

  const onInputNewMessageCallback = useCallback((newInputMsg) => {
    sendMessageToChatBot(newInputMsg);
  });

  const onPressNewSessionCallback = useCallback(() => {
    sessionExpireModalRef.current.hide();
    SessionLogoutModalRef.current.hide();
    sessionExpire(false);
  });

  
  const onPressSessionBackCallback = useCallback(() => {
    SessionLogoutModalRef.current.hide();
    // sessionExpire(false);
  });
  const onPressLogoutCallback = useCallback(() => {
    StopCamera_Modal();
    logout();
  });

  const onPressSessionEndCallback = useCallback(() => {
    sessionEndedModalRef.current.hide();
    sessionExpire(false);

    // setTimeout(() => {
    //   setIsChatOrClear(true);
    //   sendMessageToChatBot("hello");
    // }, 1000);
  });

  const onPressLetsGoCallback = useCallback(() => {
    // chatListRef.current.clearChat();
    // sendMessageToChatBot("hello");
    Newsession();
    // loadModels();

    // handleFacialExp();

    // setOpen(true)
  }, []);

  const onSelectTextCallback = useCallback((text) => {
    let msg = {
      button: [],
      isClient: true,
      text_eng: text,
      msgTime: getDateTime(),
    };
    chatListRef.current.addMessage(msg);
    sendMessageToChatBot(text);
  }, []);

  const onSessionEndTriggerCallback = useCallback(() => {
    sendChatListToChatBot();
  }, []);

  const onStopVideoAndVoiceCallback = useCallback(() => {
    stopVideo();
    window.cancelSpeak();
  }, []);

  const clearSession = () => {
    stopVideo();
    window.cancelSpeak();
    startTime = "";
    CHAT_SESSOIN = "";
    CHAT_SESSOIN = uuidv4();
  };

  useEffect(() => {
    chatListRef.current.inputDisable();
    // setIsChatOrClear(true)
    if (window.performance) {
      if (performance.navigation.type == 1) {
        window.cancelSpeak();
        // alert( "This page is reloaded" );
      } else {
        // alert( "This page is not reloaded");
      }
    }
    policyAgreementModalRef.current.show();
  }, []);

  const loadModels = async () => {
    debugger;
    const MODEL_URL = process.env.PUBLIC_URL + "/models";
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    ]).then(startCamera);
  };

  useEffect(() => {
    results.map((result, index) => {
      if (index === results.length - 1) {
        let msg = {
          button: [],
          isClient: true,
          text_eng: result?.transcript,
          msgTime: getDateTime(),
        };
        chatListRef.current.addMessage(msg);
        sendMessageToChatBot(result?.transcript);
      }
    });
  }, [results]);

  function startCamera() {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then(function (stream) {
          if (videoElement.current) videoElement.current.srcObject = stream;
          const track = stream.getVideoTracks()[0];
          handleFacialExp();
          //Create image capture object and get camera capabilities
          // const imageCapture = new ImageCapture(track);
          // const photoCapabilities = imageCapture
          //   .getPhotoCapabilities()
          //   .then(() => {
          //     track.applyConstraints({
          //       advanced: [{ torch: true }]
          //     });
          //   });
        })
        .catch(function (error) {
          alert("Please check your device permissions");
          console.log("Something went wrong!");
          console.log(error);
        });

      if (videoElement.current)
        videoElement.current.onloadeddata = function () {
          if (window.NativeDevice)
            window.NativeDevice.htmlCameraReadyToRecord(true);
        };
    }
  }

  const stopVideoOnly = () =>
    videoElement.current?.srcObject?.getTracks()?.forEach((t) => t.stop());

  const DecimalPlaces = (value) => {
    if (value !== null) {
      var res = Math.round(value.toFixed(4) * 100);
      if (res >= 40 && res <= 100) {
        return true;
      }
      return false;
    } else return false;
  };

  const handleFacialExp = () => {
    FacialExpressions.current = setInterval(async () => {
      const detections = await faceapi
        .detectAllFaces(
          videoElement.current,
          new faceapi.TinyFaceDetectorOptions()
        )
        .withFaceLandmarks()
        .withFaceExpressions();

      if (detections.length > 0) {
        var data = detections[0].expressions;

        Object.keys(data).map(function (key, index) {
          if (DecimalPlaces(data[key])) {
            detectionObj[key] = parseInt(detectionObj[key]) + 1;
          }
        });
        
        console.log(detectionObj);
      }
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(FacialExpressions.current);
  };
  const playVideo = () => {
    videoViewRef.current.playVideo();
  };

  const stopVideo = () => {
    videoViewRef.current.stopVideo();
  };

  const speakByText = (text) => {
    window.speak(text, (isVoiceStated) => {
      if (isVoiceStated) {
        playVideo();
        CHATBOT_IS_SPEAKING = true;
        stopSpeechToText();
      }
      if (!isVoiceStated) {
        stopVideo();
        CHATBOT_IS_SPEAKING = false;
        if (CHATBOT_IS_SPEAKING === false && MIC_IS_RECOGNIZING === true) {
          startSpeechToText();
          MIC_IS_RECOGNIZING = true;
        }
      }
    });
  };

  const checkTimeIsRemaining = (startTime) => {
    // start time and end time

    var endTime = moment(new Date().getTime());
    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    if (minutes > 9) {
      return false;
    }
    return true;
  };

  function getDateTime() {
    let date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var second = date.getSeconds();

    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    if (month.toString().length == 1) {
      month = "0" + month;
    }
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (hours.toString().length == 1) {
      hours = "0" + hours;
    }
    if (minutes.toString().length == 1) {
      minutes = "0" + minutes;
    }
    if (second.toString().length == 1) {
      second = "0" + second;
    }

    var dateTime =
      day +
      "-" +
      month +
      "-" +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      second +
      " " +
      ampm;
    return dateTime;
  }

  const toggleChatOrClearChat = () => {
    setIsChatOrClear((isChatOrClear) => !isChatOrClear);
  };

  const sessionExpire = (isExpired) => {
    clearAllSession().then((res) => {
      if (
        typeof res.data.message !== "undefined" &&
        res.data.message === "CONTEXT_CLEARED"
      ) {
        console.log("context clear", res);
        setIsSessionExpired(false);
        clearSession();
        chatListRef.current.clearChat();
        setIsChatOrClear(false);
        chatListRef.current.inputEnable();
        setIsMicEnable(true);
        CHAT_START_TIME = "";
        StopCamera_Modal();

        if (!isExpired) {
          var users = JSON.parse(localStorage.getItem("userVs"));
          setTimeout(() => {
            setIsChatOrClear(true);
            sendMessageToChatBot(users.user_session);
            //sendMessageToChatBot("Depression CBT Session 2");
          }, 100);

          startCamera();
        }
      }
    });
  };
  const Newsession = () => {
    var users = JSON.parse(localStorage.getItem("userVs"));
    clearAllSession().then((res) => {
      if (
        typeof res.data.message !== "undefined" &&
        res.data.message === "CONTEXT_CLEARED"
      ) {
        debugger
        if(users.user_session==="Sessions Ended"){
          SessionCompletedModalRef.current.show();
        }
        else{
        chatListRef.current.clearChat();
        sendMessageToChatBot(users.user_session);
        // sendMessageToChatBot("Depression CBT Session 2");
        loadModels();
      }}
      // startCamera()
    });
   


  };

  const clearAllSession = () => {
    return axiosInstance.post(`/clear_context`, { user_id: user.id });
  };
  const toggleMic = () => {
    stopVideo();
    window.cancelSpeak();
    if (isRecording) {
      stopSpeechToText();
      MIC_IS_RECOGNIZING = false;
    } else {
      startSpeechToText();
      MIC_IS_RECOGNIZING = true;
    }
  };

  const speakByText2 = (text, response) => {
    if (text === "Greetings! What would you like me to call you?") {
      if (response)
        window.speak(text, (isVoiceStated) => {
          if (isVoiceStated) {
            playVideo();
            setTimeout(() => {
              stopVideo();
              setChatMsgs(response);
            }, 3000);
          }
        });
    } else {
      if (response) {
        window.speak(text, (isVoiceStated) => {
          if (isVoiceStated) {
            playVideo();

            if (text === "Greetings! What would you like me to call you?") {
              setTimeout(() => {
                stopVideo();
                setChatMsgs(response);
              }, 5000);
            }
          }
          if (!isVoiceStated) {
            stopVideo();
            setChatMsgs(response);
          }
        });
      }
    }
  };

  let index = 0;
  let speachTime = 0;

  let speachText = "";

  const setChatMsgs = async (response) => {
    if (response.data?.text_eng.length > 1) {
      if (index <= response.data?.text_eng.length - 1) {
        chatListRef.current.inputDisable();
        setIsMicEnable(false);

        // setTimeout(async () => {

        speachText += response.data?.text_eng[index] + ",";

        let msg = {
          button: response.data?.button,
          isClient: false,
          text_eng: response.data?.text_eng[index],
          msgTime: getDateTime(),
        };
        chatListRef.current.addMessage(msg);
        startTime = new moment(new Date().getTime());
        setTimeout(() => {
          chatListRef.current.scrollUp();
        }, 500);

        if (index === response.data?.text_eng.length - 1) {
          chatListRef.current.inputEnable();
          setIsMicEnable(true);
        }
        if (index === response.data?.text_eng.length) {
          speakByText2(speachText, null);
          if (response.data?.button.length < 1) {
            chatListRef.current.inputEnable();
          }
        } else {
          speakByText2(response.data?.text_eng[index], response);
        }
        index++;
        // }, 1000);
      } else {
        index = 0;
        // speachTime = 0
      }
    } else {
      speakByText(response.data?.text_eng[0]);

      let msg = {
        button: response.data?.button,
        isClient: false,
        text_eng: response.data?.text_eng[0],
        msgTime: getDateTime(),
      };
      chatListRef.current.addMessage(msg);
      startTime = new moment(new Date().getTime());
      setTimeout(() => {
        chatListRef.current.scrollUp();
      }, 500);
      if (response.data?.button.length < 1) {
        chatListRef.current.inputEnable();
        setIsMicEnable(true);
      }
    }
  };

  const checkForSaveDetails = (userPreviousMsg, newChatBotData) => {
    if (newChatBotData?.save_user_input !== "") {
      switch (newChatBotData?.save_user_input) {
        case "Name":
          NAME = userPreviousMsg;
          break;
        case "Age":
          AGE = userPreviousMsg;
          break;
        case "Pronoun":
          GENDER = userPreviousMsg;
          break;
        case "Marital":
          MARITAL = userPreviousMsg;
          break;
        case "Diagnosis":
          DIAGNOSIS = newChatBotData?.save_user_disgnosis;
          break;

        default:
          break;
      }
    }
  };

  const sendMessageToChatBot = (msg) => {
    if (CHAT_START_TIME === "") {
      CHAT_START_TIME = getDateTime();
    }

    window.cancelSpeak();
    stopVideo();

    if (checkTimeIsRemaining(startTime)) {
      var users = JSON.parse(localStorage.getItem("userVs"));
      let userTime = getDateTime();

      let requestBody = {
        user_input: msg,
        user_id: user.id,
        id_token: users.id_token,
      };
      console.log("USer object", requestBody);
      try {
        axiosInstance
          .post("/get_bot_response", requestBody)
          .then(function (response) {
            console.log(
              "Get Bot response session status",
              response.data.is_session_ended
            );
            console.log("Get Bot response", response.data);
            if (
              typeof response.data.message !== "undefined" &&
              response.data.message === "INVALID_ID_TOKEN"
            ) {
              try {
                RefreshToken().then((res) => {
                  if (
                    typeof res.data.message !== "undefined" &&
                    res.data.message === "TOKEN_REFRESHED"
                  ) {
                    users.id_token = res.data.data[0].new_id_token;
                    localStorage.setItem("userVs", JSON.stringify(users));
                    sendMessageToChatBot(msg);
                  }
                });
              } catch (e) {
                console.log(e);
              }
            }
            else if (response.data.is_session_ended === true) {
              
              face_expressionArr.push(detectionObj);

              // Calling APi function

              setTimeout(() =>  sendChatListToChatBot(), 8000);
              // sendChatListToChatBot();
              setChatMsgs(response);
              CHAT_LIST.push({
                user_time: userTime,
                bot_time: getDateTime(),
                user: msg,
                bot: response.data?.text_eng,
              });
              console.log("Chat at line 627 is:", CHAT_LIST);
              console.log("Session Array is", Session);
              checkForSaveDetails(msg, response?.data);
            } else {
              setChatMsgs(response);
              CHAT_LIST.push({
                user_time: userTime,
                bot_time: getDateTime(),
                user: msg,
                bot: response.data?.text_eng,
              });
              console.log("Chat at line 627 is:", CHAT_LIST);
              console.log("Session Array in else is", Session);

              checkForSaveDetails(msg, response?.data);
            }
            // setIsChatOrClear(true);
          })
          .catch(function (error) {
            console.log(error);
            alert(error.message);
          });
      } catch (error) {
        console.log("Error : ", error);
        // alert(JSON.stringify(error));
      }
    } else {
      // setIsSessionExpired(true)
      sessionExpireModalRef.current.show();
    }
  };

 

  const sendChatListToChatBot = () => {
    var users = JSON.parse(localStorage.getItem("userVs"));
    // const user_session=users.user_session;
    // data.conversation[users.user_session]=users.user_session;
    console.log("Expression Array is", face_expressionArr);

    const expressionObject = face_expressionArr[0];
    const face_expression_Obj = delete expressionObject.neutral;

    var max = Math.max.apply(
      null,
      Object.keys(expressionObject).map(function (x) {
        return expressionObject[x];
      })
    );
    var face_expressionMax = Object.keys(expressionObject).filter(function (x) {
      return expressionObject[x] == max;
    })[0];
    let expression;
    if (expressionObject[face_expressionMax] === 0) {
      expression = "neutral";
    } else {
      expression = face_expressionMax;
    }

    //  Ends here
    let data = {
      user_id: user.id,
      id_token: users.id_token,
      // next_session:Session[0],
      conversation: {
        // users.user_session:CHAT_LIST
      },
      user_details: {
        start_time: CHAT_START_TIME,
        name: NAME,
        age: AGE,
        pronoun: GENDER,
        marital_staus: MARITAL,
        diagnosis: DIAGNOSIS,
      },
    };
    data.conversation[users.user_session] = {
      face_expression: expression,
      chat_list: CHAT_LIST,
    };
    console.log("Data to be sent is====>", data);
    try {
      debugger;
      axiosInstance
        .post(`/send_user_conversation`, data)
        .then(function (response) {
          console.log("goodbye res", response);

          if (
            typeof response.data.message !== "undefined" &&
            response.data.message === "INVALID_ID_TOKEN"
          ) {
            try {
              RefreshToken().then((res) => {
                if (
                  typeof res.data.message !== "undefined" &&
                  res.data.message === "TOKEN_REFRESHED"
                ) {
                  users.id_token = res.data.data[0].new_id_token;
                  localStorage.setItem("userVs", JSON.stringify(users));
                  sendChatListToChatBot();
                }
              });
            } catch (e) {
              console.log(e);
            }
          } else if (response?.status === 200) {
            sessionExpire(true);
            CHAT_LIST = [];
            sessionEndedModalRef.current.show();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log("goodbye error", error);
    }
  };

  const StopCamera_Modal = () => {
    stopVideoOnly();
    handleReset();
    // detectionObj = {}
  };

  const RefreshToken = () => {
    return axiosInstance.post(`/user_refresh_token`, {
      refresh_token: user.refresh_token,
    });
  };

  return (
    <div
      className={style.mainContainer}
      id={`${isChatOrClear ? "blurContainer" : ""}`}
    >
      <VideoView ref={videoViewRef} id="myVideo" chatIsClear={isChatOrClear} />

      <div className="col-2">
        <div className="left-main-container">
          <div className="left-btns-container">
            <CompanyLogo />
            {/* <Logo /> */}
            <div className="infoMic">
              <span className="left-text1">
                Powered by
                <a
                  className="left-text2"
                  target="_blank"
                  href="https://dls-global.com"
                >
                  Digital Landscape
                </a>
              </span>

              {!isChatOrClear && (
                <div
                  className={style.micButtonMobile}
                  id="micButtonMobile"
                  onClick={() => {
                    toggleMic();
                  }}
                >
                  {isRecording ? (
                    <Bars
                      heigth="100%"
                      width="100%"
                      color="#429EFA"
                      ariaLabel="loading"
                    />
                  ) : (
                    <img src={ic_mic} className={style.micButtonImage} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-9 col-8">
        <div className="center-container">
          <div className={style.chatContainer}>
            <ChatList
              ref={chatListRef}
              chatIsClear={isChatOrClear}
              closechat={toggleChatOrClearChat}
              onInputNewMessage={onInputNewMessageCallback}
              onSelectText={onSelectTextCallback}
              onSessionEndTrigger={onSessionEndTriggerCallback}
              onStopVideoAndVoice={onStopVideoAndVoiceCallback}
            />
          </div>
        </div>
      </div>

      <div className="col-md-1 col-2">
        <div className={style.rightBtnContainer}>
          <div className={style.rightTopBtnContainer}>
            <div
              className={style.logoutButtonContainer}
              onClick={() => {
                // LogoutSession()
               // sessionEndedModalRef.current.show();
               SessionLogoutModalRef.current.show();
              }}
            >
              <img src={ic_logout} className={style.logoutButtonImage} />
            </div>
          </div>
          <div className={style.rightBottomBtnContainer}>
            <div
              className={style.micButtonContainer}
              id="micButtonContainer"
              onClick={() => {
                toggleMic();
              }}
            >
              {isRecording ? (
                <Bars
                  heigth="100%"
                  width="100%"
                  color="#429EFA"
                  ariaLabel="loading"
                />
              ) : (
                <img src={ic_mic} className={style.micButtonImage} />
              )}
            </div>
            <div
              className={style.chatImgButtonContainer}
              onClick={toggleChatOrClearChat}
            >
              <img
                src={isChatOrClear ? ic_cross : ic_chat}
                className={style.chatImgButton}
              />
            </div>
          </div>
        </div>
      </div>

      <SessionExpireModal
        ref={sessionExpireModalRef}
        onPressNewSession={onPressNewSessionCallback}
      />
      <SessionEndedModal
        ref={sessionEndedModalRef}
        onPressNewSession={onPressSessionEndCallback}
        onPressLogout={onPressLogoutCallback}
      />
       <SessionCompletedModal
        ref={SessionCompletedModalRef}
        onPressNewSession={onPressSessionEndCallback}
        onPressLogout={onPressLogoutCallback}
      />
       <SessionLogoutModal
        ref={SessionLogoutModalRef}
        onPressNewSession={onPressNewSessionCallback}
        onPressLogout={onPressLogoutCallback}
        onPressBackSession={onPressSessionBackCallback}
      />
      <PolicyAgreementModal
        ref={policyAgreementModalRef}
        onPressLetsGo={onPressLetsGoCallback}
      />

      {/* --------------------Facial Expressions Video Div---------------------------- */}

      {/* {initial &&  */}
      <video
        ref={videoElement}
        autoPlay
        muted
        height={videoHeight}
        width={videoWidth}
        style={{ opacity: 0 }}
      ></video>
      {/* } */}
    </div>
  );
};

const useStyle = createUseStyles({
  mainContainer: {
    display: "flex",
    top: "0%",
    left: "0%",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    position: "absolute",
  },
  chatContainer: {
    // minWidth: '40%',
    // width: '40%',
    padding: "0 20px",
    width: (props) => (props.isChatOrClear ? "100%" : "0%"),
    height: "100vh",
    opacity: (props) => (props.isChatOrClear ? 1 : 0),
    display: "flex",
    justifyContent: "end",
  },
  rightBtnContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "15%",
    paddingRight: "10px",
  },
  rightTopBtnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  rightBottomBtnContainer: {
    marginBottom: "30px",
    marginLeft: "10px",
  },
  logoutButtonContainer: {
    marginTop: "10px",
    background: "white",
    padding: "1.6vh",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  micButtonMobile: {
    width: "6.5vh",
    height: "6.5vh",
    background: "white",
    padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  micButtonContainer: {
    width: "6.5vh",
    height: "6.5vh",
    background: "white",
    padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  brainButtonContainer: {
    background: "white",
    // padding: '1%',
    borderRadius: "100%",
    top: "2%",
    left: "1%",
    position: "absolute",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  chatImgButtonContainer: {
    background: "white",
    padding: "1.8vh",
    marginTop: "5px",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  chatImgButton: {
    width: "3vh",
    height: "3vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  logoutButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  micButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  brainButtonImage: {
    width: "6.5vh",
    height: "6.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  chatExpiryScreen: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "transparent",
    position: "absolute",
    left: 0,
    zIndex: 12000,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    transition: "top 0.5s ease-in-out",
    top: (props) => (props.isSessionExpired ? 0 : "-100%"),
  },
  chatExpiryPopupView: {
    width: "20%",
    height: "30%",
    backgroundColor: "white",
    alignSelf: "center",
    borderRadius: "8%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  sessionModalHeader: {
    textAlign: "center",
  },
  sessionModalDesc: {
    textAlign: "center",
    color: "#00000050",
  },
  sessionModalButton: {
    width: "12vh",
    height: "4vh",
    color: "white",
    alignSelf: "center",
    backgroundColor: "black",
    borderColor: "transparent",
    borderRadius: 100,
  },
  brainButtonImage: {
    width: "7vh",
    height: "7vh",
    objectFit: "contain",
    alignSelf: "center",
  },
});

export default React.memo(Main);
