import React from "react";
import { Router, Route, Switch } from 'react-router-dom';
import history from './history.js'
import routes from './routes.js'
import sessionsRoutes from './sessionsRoutes.js'
import AppContext from './Authentication/AppContext'
import { AuthProvider } from './Authentication/JwtAuthentication'
import AuthGuard from './Authentication/authGuard'

export default () => (
<AppContext.Provider value={{ routes }}>
  <Router history={history}>
    <>
      <AuthProvider>
        <Switch>
        {sessionsRoutes.map((item, index) => {
                return (
                  <Route
                    key={index}
                    path={item.path}
                    exact={item.exact}
                    component={item.component}

                    // component={() => {
                    //   return (
                    //     <item.layout >
                    //       <item.component />
                    //     </item.layout>
                    //   );
                    // }}
                  />
                );
              }
              )}
          <AuthGuard>
            {routes.map((item, index) => {
              return (
                <Route
                  key={index}
                  exact={item.exact}
                  path={item.path}
                  component={item.component}
                  // component={() => {
                  //   return (
                  //     <item.layout >
                  //       <item.component />
                  //     </item.layout>
                  //   );
                  // }}
                />
              );
            }
            )}
          </AuthGuard>
        </Switch>

      </AuthProvider>
    </>

  </Router>
</AppContext.Provider>


);
