import ic_plus from '../../assets/icons/ic_plus.png'
import ic_checked from '../../assets/icons/ic_checked.png'
import ic_unchecked from '../../assets/icons/ic_unchecked.png'
import "../../assets/styles/style.css"
import React, { useImperativeHandle, useEffect, forwardRef, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { PolicyInnerDetailsModal } from '../chatModule'
import { Modal } from 'react-bootstrap';
const title1 = 'WHO IS RUNNING THIS SYSTEM?'
const title2 = 'WHAT INFORMATION ARE WE COLLECTING?'
const title3 = 'WHY ARE WE COLLECTING THIS INFORMATION?'

const desc1 = 'Soul Machines Limited is a New Zealand company whose address is L1,\n106 Customs Street West, Auckland, 1010, New Zealand.\n\nSoul Machines have built a “Digital Brain”\nand “Embodied Cognitive User Experience” which we call a “Digital Person”.'
const desc2 = 'For the Digital Person to be able to interact with you in an authentic and human way,\n\n their Digital Brain™ needs to collect and process information about your expressions.\nAs soon as it is collected, this information is anonymized.\n\n We do not keep this information, provide it to third parties, or share it. It is purely used while you are interacting with the Digital Person to generate the best experience possible.'
const desc3 = 'We want your experience with the Digital Person to be as fun, natural and engaging as possible.\n You can help us do this by allowing the Digital Person to study and respond to your expressions in order to interpret your emotional response so that they can provide an appropriate answer. \nIt’s just like a conversation with a real person where they are not just interpreting what you say,\n but how you say it.In the process, you are helping advance technology and experiencing the future of human-to-machine interaction.All conversation data collected is anonymized and complies with current privacy practices and regulations.\n\n We take your privacy very seriously.'


const PolicyAgreementModal = (props, ref) => {

    let { onPressLetsGo } = props
    const [isChecked, setIsChecked] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false)
    const policyInnerDetailsModalRef = useRef()

    const style = useStyle({ isModalShow, isChecked })

    useImperativeHandle(ref, () => ({
        show: () => {
            setIsModalShow(true)
        },
        hide: () => {
            setIsModalShow(false)
        },
    }));

    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };

    const onPressButton = () => {
        setIsModalShow(false)
        onPressLetsGo()
    }

    return (
        <Modal show={isModalShow} animation={false} size="lg">
            <Modal.Body className={style.ModalBody} id="modalContainer">
                <h3 className={style.headerLabel} id="headerLabel">First and Foremost</h3>
                <h3 className={style.smallHeaderLabel} id="smallHeaderLabel" style={{paddingBottom: "12px"}}>I'D LIKE TO HEAR YOUR VOICE SO THAT I CAN DO MY BEST WORK.</h3>
                <div className={style.contentsContainer} id="desc">
                    <p className={style.desc} style={{ marginTop: '12px', lineHeight: 1.5 }}>
                        This will be similar to a phone conversation where we may converse. If that's fine with you, please grant us access to your microphone when we ask.
                        Your internet connection speed can have a significant influence on call quality; if you are experiencing poor quality, we'd appreciate it if you could do a speed test.
                        When you're in a noisy area or there are other people talking around you, it might be difficult for me to hear you. Please call me from a quiet location and we'll keep this one-on-one for now.
                        I am here to help you improve your mental health and wellbeing. Everything will be kept out of the public eye.
                    </p>
                    <h3 className={style.smallHeaderLabel} id="smallHeaderLabel">
                        WHAT AND WHY ARE WE COLLECTING THIS INFORMATION?
                    </h3>
                    <p className={style.desc} style={{ marginTop: '2%', lineHeight: 1.5 }}>
                        We're recording your chat in order to diagnose any mental illnesses you could be experiencing. This information will also assist me in improving my accuracy in replying to users.
                        This data is anonymous as soon as it is obtained. We do not disclose or transfer this information to third parties. It's only used while you're engaging with the Digital Person to provide you the greatest possible experience.
                        All conversation data collected is anonymized and complies with current privacy practices and regulations. We take your privacy very seriously.
                    </p>
                </div>
                <p className="policycondition">If you’re happy to proceed on this basis, please confirm your acceptance of our Privacy Policy:</p>
                <div className={style.checkedButtonContainer} id="checkedButtonContainer" onClick={() => { handleOnChange() }} >
                    <img src={isChecked ? ic_checked : ic_unchecked} className={style.checkbox} id="checkbox" />
                    <p className={style.desc} style={{ marginLeft: '3%', color: '#429EFA', fontSize: '1.7vh', lineHeight: 1.5 }}  >
                        ACCEPT END USER AGREEMENT ALLOW ACCESS TO MICROPHONE AND USAGE DATA.
                    </p>
                </div>
                <div className={style.buttonContainer} >
                    <button className={style.button} id="modalbtn" onClick={() => { onPressButton() }}>LET'S GO!</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const useStyle = createUseStyles({
    ModalBody: {
        padding: "30px 40px 15px 40px",
    },
    mainContainer: {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 0,
        zIndex: 12000,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "top 0.5s ease-in-out",
        top: (props) => props.isModalShow ? 0 : '-100%',

    },
    modalContainer: {
        width: '60%',
        height: '85%',
        backgroundColor: 'white',
        alignSelf: 'center',
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    modalContentsMainContainer: {
        width: '80%',
        height: '85%',
        backgroundColor: 'white',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },

    headerLabel: {
        // textAlign: 'center',
        width: '100%',
        color: '#429EFA',
        fontSize: '4vh',
        margin: 0
    },

    smallHeaderLabel: {
        width: '100%',
        color: '#2A2A2A',
        fontWeight: '500',
        fontSize: '2vh',
        margin: 0,
        marginTop: '1%'

    },
    desc: {
        width: '100%',
        maxWidth: '100%',
        // textAlign: 'center',
        color: 'black',
        margin: 0,
        fontWeight: 'normal',
        fontSize: '1.5vh',
        flexShrink: 1,
        color: '#2A2A2A',

    },
    buttonContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        pointerEvents: (props) => props.isChecked ? 'auto' : 'none',

    },
    checkedButtonContainer: {
        width: '100%',
        justifyContent: 'center',
        // alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
        padding: "15px 12px 16px 0px"
    },
    button: {
        margin: '2%',
        color: 'white',
        // backgroundColor: '#429EFA',
        backgroundColor: (props) => props.isChecked ? '#429EFA' : '#00000050',
        borderColor: 'transparent',
        padding: '1%',
        borderRadius: 100,
        paddingLeft: '8%',
        paddingRight: '8%',
        cursor: 'pointer'

    },
    contentsContainer: {
        // flex: 1,
        // display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        // alignSelf: 'center',
        // justifyContent: 'center',
        // alignItems: 'center',
        // display: 'flex',
        flexDirection: 'column',
        // overflowY: 'scroll',
        // overflowX: 'hidden',
        scrollBehavior: 'smooth',
        // whiteSpace: 'nowrap',
        height: "230px",
        overflowX: 'hidden',
        overflowY: 'scroll',
        border: "1px solid #cfcfcf",
        margin: 0,
        marginTop: '1%',
        '&::-webkit-scrollbar': {
            width: '1vh'
        },

        /* Track */
        '&::-webkit-scrollbar-track': {
            'box-shadow': 'inset 0 0 1vh #00000020',
            'border-radius': '10px',
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            'background': '#00000020',
            'border-radius': '10px'
        },


    },
    clickableOptionContainer: {
        width: '100%',
        height: '10%',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: '1%',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'

    },
    questionLabel: {
        width: '60%',
        fontSize: '1.8vh',
        fontWeight: 'normal',
        padding: 0,
        margin: 0,
        alignSelf: 'center',
        color: '#429EFA',


    },
    questionIcon: {
        width: '1.5vh',
        height: '1.5vh',
        objectFit: 'contain',
        objectPosition: 'center',
        alignSelf: 'center',
    },
    checkbox: {
        width: '3vh',
        height: '3vh',
        borderRadius: 100
    }
})

export default forwardRef(PolicyAgreementModal);
